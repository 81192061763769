import React from 'react';
import { Link } from 'react-router-dom';
import './SetupGuide.css';

function SetupGuide() {
  return (
    <div className="setup-guide-container">
      <h1>ZipCode AutoFill Setup Guide</h1>
      <p className="intro">
        Welcome to ZipCode AutoFill! This guide will walk you through the process of setting up our integration with your HubSpot account. Follow these steps to start automating your address management.
      </p>

      <div className="setup-steps">
        <div className="step">
          <h2>1. Create an Account</h2>
          <p>If you haven't already, <Link to="/register">sign up for a ZipCode AutoFill account</Link>. You'll need this to connect to your HubSpot portal and manage your credits.</p>
        </div>

        <div className="step">
          <h2>2. Connect Your HubSpot Account</h2>
          <p>From your dashboard, click on "Connect to HubSpot". You'll be redirected to HubSpot to authorize the connection. Grant the necessary permissions for ZipCode AutoFill to access your contact and company data.</p>
        </div>

        <div className="step">
          <h2>3. Configure Field Mappings</h2>
          <p>After connecting, you'll be prompted to configure which HubSpot fields should be updated with the address information. Map the following fields:</p>
          <ul>
            <li>City</li>
            <li>State</li>
            <li>County</li>
            <li>Country</li>
          </ul>
          <p>You can choose different mappings for contacts and companies.<b> Please note that the HubSpot properties you choose must be a single-line text field property type. You must also use the default postal code HubSpot property.</b></p>
        </div>

        <div className="step">
          <h2>4. Test the Integration</h2>
          <p>Every ZipCode AutoFill account comes with 10 free credits. Update a ZIP code for a test contact or company record in your HubSpot account. Refresh, and the mapped fields should automatically populate with the correct information.</p>
        </div>

        <div className="step">
          <h2>5. Purchase Credits</h2>
          <p>Visit our <Link to="/credits">Credits page</Link> to purchase more credits. Each ZIP code lookup consumes one credit. Choose a plan that suits your needs.</p>
        </div>
    </div>

      <div className="additional-info">
        <h2>Additional Information</h2>
        <ul>
          <li><strong>Our system processes 5-digit US ZIP codes and, to a limited degree, Canadian postal codes (e.g., K1L 6P3).</strong></li>
          <li>Credits are deducted only for successful lookups of valid postal codes.</li>
          <li>You can connect multiple HubSpot portals to a single ZipCode AutoFill account.</li>
          <li>For any issues or questions, please visit our <Link to="/support">Support page</Link>.</li>
        </ul>
      </div>

      <div className="video-card">
        <h3>How to Backfill Existing Records</h3>
        <div className="video-container">
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/NbnRMGvPW14?si=ugq69xE94ukiE2Rm" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerPolicy="strict-origin-when-cross-origin" 
            allowFullScreen
          ></iframe>
        </div>
      </div>
<br></br>
      <div className="cta">
        <p style={{ color: 'black' }}>Ready to get started?</p>
        <Link to="/dashboard" className="cta-button">Go to Dashboard</Link>
      </div>
    </div>
  );
}

export default SetupGuide;
