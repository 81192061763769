import React, { useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';

import { AuthProvider, useAuth } from './context/AuthContext';

import Navbar from './components/Navbar';

import Home from './components/Home';

import Login from './components/Login';

import Register from './components/Register';

import Dashboard from './components/Dashboard';

import ForgotPassword from './components/ForgotPassword';

import ResetPassword from './components/ResetPassword';

import ManageAccount from './components/ManageAccount';

import Credits from './components/Credits';

import PortalConfiguration from './components/PortalConfiguration';

import Pricing from './components/Pricing';

import Support from './components/Support';

import SetupGuide from './components/SetupGuide';

import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

import WhyUs from './components/WhyUs';

import UnsubscribeCredits from './components/UnsubscribeCredits';





class ErrorBoundary extends React.Component {

  constructor(props) {

    super(props);

    this.state = { hasError: false };

  }



  static getDerivedStateFromError(error) {

    return { hasError: true };

  }



  componentDidCatch(error, errorInfo) {

    console.log('Error:', error, errorInfo);

  }



  render() {

    if (this.state.hasError) {

      return <h1>Something went wrong.</h1>;

    }



    return this.props.children;

  }

}



function App() {

  console.log('App component rendered');

  useEffect(() => {
    // LinkedIn tracking code
    window._linkedin_partner_id = "7100572";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
    
    if (!window.lintrk) {
      window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[];
    }
    
    const s = document.getElementsByTagName("script")[0];
    const b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  }, []);

  return (

    <ErrorBoundary>

      <AuthProvider>

        <Router>

          <div className="App">

            <header>
              <div className="logo-container">
                <img src="/logo.png" alt="ZipCode AutoFill Logo" className="logo" />
              </div>
              <Navbar />
            </header>

            <main>

              <Routes>

                <Route path="/" element={<Home />} />

                <Route path="/login" element={<Login />} />

                <Route path="/register" element={<Register />} />

                <Route path="/forgot-password" element={<ForgotPassword />} />

                <Route path="/reset-password/:token" element={<ResetPassword />} />

                <Route path="/pricing" element={<PublicOnlyRoute><Pricing /></PublicOnlyRoute>} />

                <Route path="/why-us" element={<WhyUs />} />

                <Route

                  path="/dashboard"

                  element={

                    <PrivateRoute>

                      <Dashboard />

                    </PrivateRoute>

                  }

                />

                <Route

                  path="/manage-account"

                  element={

                    <PrivateRoute>

                      <ManageAccount />

                    </PrivateRoute>

                  }

                />

                <Route

                  path="/credits"

                  element={

                    <PrivateRoute>

                      <Credits />

                    </PrivateRoute>

                  }

                />

                <Route

                  path="/configure-portal/:portalId"

                  element={
                    <PrivateRoute>
                      <PortalConfiguration />
                    </PrivateRoute>
                  }

                />

                <Route
                  path="/support"
                  element={
                    <Support />
                  }
                />

                <Route path="/setup-guide" element={<SetupGuide />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />

                <Route path="/unsubscribe/:token" element={<UnsubscribeCredits />} />

              </Routes>

            </main>

            <footer>
              <p>
                Copyright © {new Date().getFullYear()} ZipCode AutoFill | 
                <a href="mailto:info@zipcodeautofill.com"> Support</a> | 
                <Link to="/privacy-policy"> Privacy Policy</Link> | 
                <Link to="/terms-of-service"> Terms of Service</Link>
              </p>
              
              {/* LinkedIn Tracking Pixel */}
              <div id="linkedin-tracking">
                <noscript>
                  <img 
                    height="1" 
                    width="1" 
                    style={{display: "none"}} 
                    alt="" 
                    src="https://px.ads.linkedin.com/collect/?pid=7100572&fmt=gif" 
                  />
                </noscript>
              </div>
            </footer>

          </div>

        </Router>

      </AuthProvider>

    </ErrorBoundary>

  );

}



const PrivateRoute = ({ children }) => {

  const { user, loading } = useAuth();



  if (loading) {

    return <div>Loading...</div>;

  }



  return user ? children : <Navigate to="/login" />;

};



const PublicOnlyRoute = ({ children }) => {

  const { user, loading } = useAuth();

  if (loading) {

    return <div>Loading...</div>;

  }

  return user ? <Navigate to="/dashboard" /> : children;

};



export default App;
